<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div style="margin-bottom: 10px" v-if="info.level !== '6'">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />开设账号
      </el-button>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="80"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="account" label="账户名"></el-table-column>

        <!-- <el-table-column prop="password" label="密码"></el-table-column> -->
        <el-table-column prop="level" label="等级">
          <template slot-scope="scope">
            {{ scope.row.level | level }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime("YMDHMS") }}
          </template>
        </el-table-column>
        <el-table-column prop="lastLoginTime" label="最后登录时间">
          <template slot-scope="scope">
            {{ scope.row.lastLoginTime | formatTime("YMDHMS") }}
          </template>
        </el-table-column>
        <el-table-column prop="province" label="省"></el-table-column>
        <el-table-column prop="city" label="市"></el-table-column>
        <el-table-column prop="area" label="区"></el-table-column>
        <el-table-column prop="school" label="学校"></el-table-column>
        <el-table-column prop="classLevel" label="年级"></el-table-column>
        <el-table-column prop="classField" label="班级"></el-table-column>
        <el-table-column prop label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="resetPwd(scope.row)"
              >重置密码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <UserDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import UserDialog from "./components/dialog.vue";
import { formatTime } from "video.js";
export default {
  components: { searchBar, UserDialog },
  data() {
    return {
      page: {
        toPageNum: 1,
        pageSize: 10,
      },
      params: {},
      total: 0,
      tableData: [],
      adInfo: {
        province: "",
        city: "",
        area: "",
        school: "",
        classLevel: "",
        classField: "",
      },
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "account",
          attrs: {
            placeholder: "账号名称",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "level",
          data: [
            {
              label: "超级管理员",
              value: "0",
            },
            {
              label: "省份",
              value: "1",
            },
            {
              label: "城市",
              value: "2",
            },
            {
              label: "区域",
              value: "3",
            },
            {
              label: "学校",
              value: "4",
            },
            {
              label: "专家",
              value: "5",
            },
            {
              label: "班级",
              value: "6",
            },
            {
              label: "年级",
              value: "7",
            },
          ],
          attrs: {
            placeholder: "账号等级",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "province",
          data: [],
          attrs: {
            placeholder: "省",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "city",
          data: [],
          attrs: {
            placeholder: "市",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "area",
          data: [],
          attrs: {
            placeholder: "区",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "school",
          data: [],
          attrs: {
            placeholder: "学校",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "classLevel",
          data: [],
          attrs: {
            placeholder: "年级",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "classField",
          data: [],
          attrs: {
            placeholder: "班级",
          },
        },
      ],
      info: {}, //当前登录用户信息
    };
  },
  methods: {
    changeSelect(data) {
      if (data.item.fieldName === "province") {
        this.adInfo.province = data.opt;
        this.$refs.searchBar.formData.city = "";
        this.$refs.searchBar.formData.area = "";
        this.$refs.searchBar.formData.school = "";
        this.$refs.searchBar.formData.classLevel = "";
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(3);
      } else if (data.item.fieldName === "city") {
        this.adInfo.city = data.opt;
        this.$refs.searchBar.formData.area = "";
        this.$refs.searchBar.formData.school = "";
        this.$refs.searchBar.formData.classLevel = "";
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(4);
      } else if (data.item.fieldName === "area") {
        this.adInfo.area = data.opt;
        this.$refs.searchBar.formData.school = "";
        this.$refs.searchBar.formData.classLevel = "";
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(5);
      } else if (data.item.fieldName === "school") {
        this.adInfo.school = data.opt;
        this.$refs.searchBar.formData.classLevel = "";
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(6);
      } else if (data.item.fieldName === "classLevel") {
        this.adInfo.classLevel = data.opt;
        this.$refs.searchBar.formData.classField = "";
        this.getAdInfo(7);
      } else if (data.item.fieldName === "classField") {
        this.adInfo.classLevel = data.opt;
      }
    },
    indexMethod(index) {
      return index + 1 + (this.page.toPageNum - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.toPageNum = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.page.toPageNum = 1;
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/admin/admin/page",
        { ...this.params, ...this.page },
        "post"
      ).then((res) => {
        this.tableData = res.data.content;
        this.total = res.data.totalElements;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
      this.$refs.dialog.getInfo(this.info);
    },
    resetPwd(item) {
      const _this = this;
      this.$confirm("确定重置密码吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        _this
          .getAjax(
            "api/admin/resetPwd",
            {
              str: item.account,
            },
            "post"
          )
          .then((res) => {
            this.$message.success("重置成功");
            this.showChangePwd = false;
            this.initPage();
          });
      });
    },

    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax(
          "api/admin/doctor/delDoctor",
          { id: item.id },
          "post"
        ).then((res) => {
          this.$message.success("删除成功");
          this.initPage();
        });
      });
    },
    getAdInfo(num) {
      this.getAjax(
        "api/admin/getCity",
        {
          ...this.adInfo,
        },
        "post"
      ).then((res) => {
        this.formItems[num].data = res.data.map((e) => {
          return {
            label: e,
            value: e,
          };
        });
        for (let i = num + 1; i < 8; i++) {
          this.formItems[i].data = [];
        }
      });
    },
  },
  mounted() {
    this.initPage();
    //获取当前账号级别
    this.getAjax("api/admin/getInfo", {}, "post").then((res) => {
      this.info = res.data;
    });
    this.getAdInfo(2);
  },
};
</script>

<style lang="less"></style>
