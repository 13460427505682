<template>
  <div class="activityDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form ref="dialogForm" :model="form" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="账户名">
              <el-input v-model="form.account"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="info.level === '0'">
            <el-form-item label="类型">
              <el-radio v-model="type" label="1">省</el-radio>
              <el-radio v-model="type" label="5">专家</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="info.level === '0' && type === '1'">
            <el-form-item label="省">
              <el-input v-model="form.province"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="info.level === '1'">
            <el-form-item label="市">
              <el-input v-model="form.city"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="info.level === '2'">
            <el-form-item label="区">
              <el-input v-model="form.area"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="info.level === '3'">
            <el-form-item label="学校">
              <el-input v-model="form.school"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="info.level === '4'">
            <el-form-item label="年级">
              <el-input v-model="form.classLevel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="info.level === '7'">
            <el-form-item label="班级">
              <el-input v-model="form.classField"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "开设账号",
      dialogImageUrl: "",
      dialogVisible: false,
      headers: {
        authorization: localStorage.getItem("token"),
      },
      memberId: "",
      type: "1", //1省2专家
      form: {
        account: "",
        password: "123456",
        level: "",
        province: "", //省
        city: "", //市
        area: "", //区
        school: "", //学校
        classLevel: "", //年级
        classField: "", //班级
      },
      info: {}, //当前用户信息
    };
  },
  components: {},
  methods: {
    handleAvatarSuccess(res, file) {
      this.form.headImg = res.data.downloadUrl;
    },
    handleAvatarSuccess2(res, file) {
      this.form.signImg = res.data.downloadUrl;
    },
    handleAvatarSuccess3(res, file) {
      this.form.detailImg = res.data.downloadUrl;
    },
    async submitData() {
      let params = JSON.parse(JSON.stringify(this.form));
      if (this.info.level === "0") {
        params.level = this.type;
      }else if(this.info.level === "1"){
        params.level = '2';
      }else if(this.info.level === "2"){
        params.level = '3';
      }else if(this.info.level === "3"){
        params.level = '4';
      }else if(this.info.level === "4"){
        params.level = '7';
      }else if(this.info.level === "7"){
        params.level = '6';
      }
      let url = "api/admin/openAccount";
      this.getAjax(url, params, "post").then(() => {
        this.$message.success("操作成功");
        this.closeEvent();
        this.$emit("search");
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增管理员";
      this.form = {};
    },
    getInfo(item) {
      this.info = item;
      this.visible = true;
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
